import { default as _91slug_93jVFMKKR9ttMeta } from "/vercel/path0/frontend/marketplace/pages/articles/[slug].vue?macro=true";
import { default as indexUcwo3wMXZ9Meta } from "/vercel/path0/frontend/marketplace/pages/articles/index.vue?macro=true";
import { default as _91slug_93V3UyOG5sg6Meta } from "/vercel/path0/frontend/marketplace/pages/articles/preview/[slug].vue?macro=true";
import { default as _91tag_93m0POJ9j6diMeta } from "/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/[tag].vue?macro=true";
import { default as index4s5pU0MmVOMeta } from "/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/index.vue?macro=true";
import { default as _91slug_937ho60udpTiMeta } from "/vercel/path0/frontend/marketplace/pages/auteurs/[slug].vue?macro=true";
import { default as _91slug_93TT89yMRTxkMeta } from "/vercel/path0/frontend/marketplace/pages/auteurs/preview/[slug].vue?macro=true";
import { default as connexion1fkdNN3Af8Meta } from "/vercel/path0/frontend/marketplace/pages/auth/connexion.vue?macro=true";
import { default as inscriptionjOxOTEpJAFMeta } from "/vercel/path0/frontend/marketplace/pages/auth/inscription.vue?macro=true";
import { default as reinitialiserRcIaF3keTsMeta } from "/vercel/path0/frontend/marketplace/pages/auth/reinitialiser.vue?macro=true";
import { default as _91slug_93SZQfSx9vN3Meta } from "/vercel/path0/frontend/marketplace/pages/collections/[slug].vue?macro=true";
import { default as _91slug_932BhidoC3lYMeta } from "/vercel/path0/frontend/marketplace/pages/collections/preview/[slug].vue?macro=true";
import { default as indexTOyjF8A9nbMeta } from "/vercel/path0/frontend/marketplace/pages/index.vue?macro=true";
import { default as _91slug_93d7QYFITEQxMeta } from "/vercel/path0/frontend/marketplace/pages/legal/[slug].vue?macro=true";
import { default as _91query_93IqETIAs31jMeta } from "/vercel/path0/frontend/marketplace/pages/membres/[category]/[query].vue?macro=true";
import { default as indexxyXD889UmXMeta } from "/vercel/path0/frontend/marketplace/pages/membres/[category]/index.vue?macro=true";
import { default as _91slug_93MHQLPHGAY1Meta } from "/vercel/path0/frontend/marketplace/pages/membres/leaderboards/[slug].vue?macro=true";
import { default as index0Ep8aKxfcPMeta } from "/vercel/path0/frontend/marketplace/pages/membres/leaderboards/index.vue?macro=true";
import { default as _91slug_93EQzR0ZonlfMeta } from "/vercel/path0/frontend/marketplace/pages/membres/leaderboards/preview/[slug].vue?macro=true";
import { default as _91id_936c4SMiOnkbMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/collection/[id].vue?macro=true";
import { default as badge_45numberse6K7Pqul0Meta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-number.vue?macro=true";
import { default as badge_45trustpowerDXpRujOf8rMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-trustpower.vue?macro=true";
import { default as collections_45step_45sliderimLWaoKphhMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/collections-step-slider.vue?macro=true";
import { default as endorsementubBZ4FtfEyMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsement.vue?macro=true";
import { default as endorsements_45gridFkntWrN6EMMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-grid.vue?macro=true";
import { default as endorsements_45infinite_45sliderzQbgq8MnzzMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-infinite-slider.vue?macro=true";
import { default as endorsements_45page_45sliderZzfE0AweOBMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-page-slider.vue?macro=true";
import { default as endorsements_45step_45sliderbRFZBqmx15Meta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-step-slider.vue?macro=true";
import { default as qualities_45gridzNmMqfWMawMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-grid.vue?macro=true";
import { default as qualities_45infinite_45slider84Nai3kJceMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-infinite-slider.vue?macro=true";
import { default as qualities_45page_45sliderlnpM4RmAaEMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-page-slider.vue?macro=true";
import { default as qualities_45step_45sliderdjiEf9StstMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-step-slider.vue?macro=true";
import { default as qualityfHfSf4JJ9GMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/quality.vue?macro=true";
import { default as index8CwUAg4kjJMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/index.vue?macro=true";
import { default as _91id_93ZSVlfvB2qSMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/qualite/[id].vue?macro=true";
import { default as _91id_93HPLJnA5qyZMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/reference/[id].vue?macro=true";
import { default as rejoindrevXXrYD37iEMeta } from "/vercel/path0/frontend/marketplace/pages/profil/[slug]/rejoindre.vue?macro=true";
import { default as _91slug_93CQ1MNEVGJaMeta } from "/vercel/path0/frontend/marketplace/pages/solutions/articles/[slug].vue?macro=true";
import { default as indexf1n2bDm7P6Meta } from "/vercel/path0/frontend/marketplace/pages/solutions/articles/index.vue?macro=true";
import { default as _91tag_93RvEGVAZCxgMeta } from "/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/[tag].vue?macro=true";
import { default as index9LTYvMAlYMMeta } from "/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/index.vue?macro=true";
import { default as _91slug_93asXtBj0zmfMeta } from "/vercel/path0/frontend/marketplace/pages/solutions/collections/[slug].vue?macro=true";
import { default as indexSgBWVjjhpdMeta } from "/vercel/path0/frontend/marketplace/pages/solutions/index.vue?macro=true";
import { default as component_45stubHUXpOjlvHkMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubHUXpOjlvHk } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "articles-slug___fr-FR",
    path: "/articles/:slug()",
    meta: _91slug_93jVFMKKR9ttMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/[slug].vue")
  },
  {
    name: "articles-slug___en-GB",
    path: "/en-GB/articles/:slug()",
    meta: _91slug_93jVFMKKR9ttMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/[slug].vue")
  },
  {
    name: "articles-slug___es-ES",
    path: "/es-ES/articles/:slug()",
    meta: _91slug_93jVFMKKR9ttMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/[slug].vue")
  },
  {
    name: "articles-slug___it-IT",
    path: "/it-IT/articles/:slug()",
    meta: _91slug_93jVFMKKR9ttMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/[slug].vue")
  },
  {
    name: "articles___fr-FR",
    path: "/articles",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/index.vue")
  },
  {
    name: "articles___en-GB",
    path: "/en-GB/articles",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/index.vue")
  },
  {
    name: "articles___es-ES",
    path: "/es-ES/articles",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/index.vue")
  },
  {
    name: "articles___it-IT",
    path: "/it-IT/articles",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/index.vue")
  },
  {
    name: "articles-preview-slug___fr-FR",
    path: "/articles/preview/:slug()",
    meta: _91slug_93V3UyOG5sg6Meta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/preview/[slug].vue")
  },
  {
    name: "articles-preview-slug___en-GB",
    path: "/en-GB/articles/preview/:slug()",
    meta: _91slug_93V3UyOG5sg6Meta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/preview/[slug].vue")
  },
  {
    name: "articles-preview-slug___es-ES",
    path: "/es-ES/articles/preview/:slug()",
    meta: _91slug_93V3UyOG5sg6Meta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/preview/[slug].vue")
  },
  {
    name: "articles-preview-slug___it-IT",
    path: "/it-IT/articles/preview/:slug()",
    meta: _91slug_93V3UyOG5sg6Meta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/preview/[slug].vue")
  },
  {
    name: "articles-tags-category-tag___fr-FR",
    path: "/articles/tags/:category()/:tag()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/[tag].vue")
  },
  {
    name: "articles-tags-category-tag___en-GB",
    path: "/en-GB/articles/tags/:category()/:tag()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/[tag].vue")
  },
  {
    name: "articles-tags-category-tag___es-ES",
    path: "/es-ES/articles/tags/:category()/:tag()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/[tag].vue")
  },
  {
    name: "articles-tags-category-tag___it-IT",
    path: "/it-IT/articles/tags/:category()/:tag()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/[tag].vue")
  },
  {
    name: "articles-tags-category___fr-FR",
    path: "/articles/tags/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/index.vue")
  },
  {
    name: "articles-tags-category___en-GB",
    path: "/en-GB/articles/tags/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/index.vue")
  },
  {
    name: "articles-tags-category___es-ES",
    path: "/es-ES/articles/tags/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/index.vue")
  },
  {
    name: "articles-tags-category___it-IT",
    path: "/it-IT/articles/tags/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/articles/tags/[category]/index.vue")
  },
  {
    name: "auteurs-slug___fr-FR",
    path: "/auteurs/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auteurs/[slug].vue")
  },
  {
    name: "auteurs-slug___en-GB",
    path: "/en-GB/auteurs/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auteurs/[slug].vue")
  },
  {
    name: "auteurs-slug___es-ES",
    path: "/es-ES/auteurs/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auteurs/[slug].vue")
  },
  {
    name: "auteurs-slug___it-IT",
    path: "/it-IT/auteurs/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auteurs/[slug].vue")
  },
  {
    name: "auteurs-preview-slug___fr-FR",
    path: "/auteurs/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auteurs/preview/[slug].vue")
  },
  {
    name: "auteurs-preview-slug___en-GB",
    path: "/en-GB/auteurs/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auteurs/preview/[slug].vue")
  },
  {
    name: "auteurs-preview-slug___es-ES",
    path: "/es-ES/auteurs/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auteurs/preview/[slug].vue")
  },
  {
    name: "auteurs-preview-slug___it-IT",
    path: "/it-IT/auteurs/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auteurs/preview/[slug].vue")
  },
  {
    name: "auth-connexion___fr-FR",
    path: "/auth/connexion",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/connexion.vue")
  },
  {
    name: "auth-connexion___en-GB",
    path: "/en-GB/auth/connexion",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/connexion.vue")
  },
  {
    name: "auth-connexion___es-ES",
    path: "/es-ES/auth/connexion",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/connexion.vue")
  },
  {
    name: "auth-connexion___it-IT",
    path: "/it-IT/auth/connexion",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/connexion.vue")
  },
  {
    name: "auth-inscription___fr-FR",
    path: "/auth/inscription",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/inscription.vue")
  },
  {
    name: "auth-inscription___en-GB",
    path: "/en-GB/auth/inscription",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/inscription.vue")
  },
  {
    name: "auth-inscription___es-ES",
    path: "/es-ES/auth/inscription",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/inscription.vue")
  },
  {
    name: "auth-inscription___it-IT",
    path: "/it-IT/auth/inscription",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/inscription.vue")
  },
  {
    name: "auth-reinitialiser___fr-FR",
    path: "/auth/reinitialiser",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/reinitialiser.vue")
  },
  {
    name: "auth-reinitialiser___en-GB",
    path: "/en-GB/auth/reinitialiser",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/reinitialiser.vue")
  },
  {
    name: "auth-reinitialiser___es-ES",
    path: "/es-ES/auth/reinitialiser",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/reinitialiser.vue")
  },
  {
    name: "auth-reinitialiser___it-IT",
    path: "/it-IT/auth/reinitialiser",
    component: () => import("/vercel/path0/frontend/marketplace/pages/auth/reinitialiser.vue")
  },
  {
    name: "collections-slug___fr-FR",
    path: "/collections/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/collections/[slug].vue")
  },
  {
    name: "collections-slug___en-GB",
    path: "/en-GB/collections/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/collections/[slug].vue")
  },
  {
    name: "collections-slug___es-ES",
    path: "/es-ES/collections/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/collections/[slug].vue")
  },
  {
    name: "collections-slug___it-IT",
    path: "/it-IT/collections/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/collections/[slug].vue")
  },
  {
    name: "collections-preview-slug___fr-FR",
    path: "/collections/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/collections/preview/[slug].vue")
  },
  {
    name: "collections-preview-slug___en-GB",
    path: "/en-GB/collections/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/collections/preview/[slug].vue")
  },
  {
    name: "collections-preview-slug___es-ES",
    path: "/es-ES/collections/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/collections/preview/[slug].vue")
  },
  {
    name: "collections-preview-slug___it-IT",
    path: "/it-IT/collections/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/collections/preview/[slug].vue")
  },
  {
    name: "index___fr-FR",
    path: "/",
    component: () => import("/vercel/path0/frontend/marketplace/pages/index.vue")
  },
  {
    name: "index___en-GB",
    path: "/en-GB",
    component: () => import("/vercel/path0/frontend/marketplace/pages/index.vue")
  },
  {
    name: "index___es-ES",
    path: "/es-ES",
    component: () => import("/vercel/path0/frontend/marketplace/pages/index.vue")
  },
  {
    name: "index___it-IT",
    path: "/it-IT",
    component: () => import("/vercel/path0/frontend/marketplace/pages/index.vue")
  },
  {
    name: "legal-slug___fr-FR",
    path: "/legal/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___en-GB",
    path: "/en-GB/legal/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___es-ES",
    path: "/es-ES/legal/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___it-IT",
    path: "/it-IT/legal/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/legal/[slug].vue")
  },
  {
    name: "membres-category-query___fr-FR",
    path: "/membres/:category()/:query()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/[category]/[query].vue")
  },
  {
    name: "membres-category-query___en-GB",
    path: "/en-GB/membres/:category()/:query()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/[category]/[query].vue")
  },
  {
    name: "membres-category-query___es-ES",
    path: "/es-ES/membres/:category()/:query()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/[category]/[query].vue")
  },
  {
    name: "membres-category-query___it-IT",
    path: "/it-IT/membres/:category()/:query()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/[category]/[query].vue")
  },
  {
    name: "membres-category___fr-FR",
    path: "/membres/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/[category]/index.vue")
  },
  {
    name: "membres-category___en-GB",
    path: "/en-GB/membres/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/[category]/index.vue")
  },
  {
    name: "membres-category___es-ES",
    path: "/es-ES/membres/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/[category]/index.vue")
  },
  {
    name: "membres-category___it-IT",
    path: "/it-IT/membres/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/[category]/index.vue")
  },
  {
    name: "membres-leaderboards-slug___fr-FR",
    path: "/membres/leaderboards/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/[slug].vue")
  },
  {
    name: "membres-leaderboards-slug___en-GB",
    path: "/en-GB/membres/leaderboards/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/[slug].vue")
  },
  {
    name: "membres-leaderboards-slug___es-ES",
    path: "/es-ES/membres/leaderboards/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/[slug].vue")
  },
  {
    name: "membres-leaderboards-slug___it-IT",
    path: "/it-IT/membres/leaderboards/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/[slug].vue")
  },
  {
    name: "membres-leaderboards___fr-FR",
    path: "/membres/leaderboards",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/index.vue")
  },
  {
    name: "membres-leaderboards___en-GB",
    path: "/en-GB/membres/leaderboards",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/index.vue")
  },
  {
    name: "membres-leaderboards___es-ES",
    path: "/es-ES/membres/leaderboards",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/index.vue")
  },
  {
    name: "membres-leaderboards___it-IT",
    path: "/it-IT/membres/leaderboards",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/index.vue")
  },
  {
    name: "membres-leaderboards-preview-slug___fr-FR",
    path: "/membres/leaderboards/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/preview/[slug].vue")
  },
  {
    name: "membres-leaderboards-preview-slug___en-GB",
    path: "/en-GB/membres/leaderboards/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/preview/[slug].vue")
  },
  {
    name: "membres-leaderboards-preview-slug___es-ES",
    path: "/es-ES/membres/leaderboards/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/preview/[slug].vue")
  },
  {
    name: "membres-leaderboards-preview-slug___it-IT",
    path: "/it-IT/membres/leaderboards/preview/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/membres/leaderboards/preview/[slug].vue")
  },
  {
    name: "profil-slug-collection-id___fr-FR",
    path: "/profil/:slug()/collection/:id()",
    meta: _91id_936c4SMiOnkbMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/collection/[id].vue")
  },
  {
    name: "profil-slug-collection-id___en-GB",
    path: "/en-GB/profil/:slug()/collection/:id()",
    meta: _91id_936c4SMiOnkbMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/collection/[id].vue")
  },
  {
    name: "profil-slug-collection-id___es-ES",
    path: "/es-ES/profil/:slug()/collection/:id()",
    meta: _91id_936c4SMiOnkbMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/collection/[id].vue")
  },
  {
    name: "profil-slug-collection-id___it-IT",
    path: "/it-IT/profil/:slug()/collection/:id()",
    meta: _91id_936c4SMiOnkbMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/collection/[id].vue")
  },
  {
    name: "profil-slug-embed-badge-number___fr-FR",
    path: "/profil/:slug()/embed/badge-number",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-number.vue")
  },
  {
    name: "profil-slug-embed-badge-number___en-GB",
    path: "/en-GB/profil/:slug()/embed/badge-number",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-number.vue")
  },
  {
    name: "profil-slug-embed-badge-number___es-ES",
    path: "/es-ES/profil/:slug()/embed/badge-number",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-number.vue")
  },
  {
    name: "profil-slug-embed-badge-number___it-IT",
    path: "/it-IT/profil/:slug()/embed/badge-number",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-number.vue")
  },
  {
    name: "profil-slug-embed-badge-trustpower___fr-FR",
    path: "/profil/:slug()/embed/badge-trustpower",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-trustpower.vue")
  },
  {
    name: "profil-slug-embed-badge-trustpower___en-GB",
    path: "/en-GB/profil/:slug()/embed/badge-trustpower",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-trustpower.vue")
  },
  {
    name: "profil-slug-embed-badge-trustpower___es-ES",
    path: "/es-ES/profil/:slug()/embed/badge-trustpower",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-trustpower.vue")
  },
  {
    name: "profil-slug-embed-badge-trustpower___it-IT",
    path: "/it-IT/profil/:slug()/embed/badge-trustpower",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/badge-trustpower.vue")
  },
  {
    name: "profil-slug-embed-collections-step-slider___fr-FR",
    path: "/profil/:slug()/embed/collections-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/collections-step-slider.vue")
  },
  {
    name: "profil-slug-embed-collections-step-slider___en-GB",
    path: "/en-GB/profil/:slug()/embed/collections-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/collections-step-slider.vue")
  },
  {
    name: "profil-slug-embed-collections-step-slider___es-ES",
    path: "/es-ES/profil/:slug()/embed/collections-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/collections-step-slider.vue")
  },
  {
    name: "profil-slug-embed-collections-step-slider___it-IT",
    path: "/it-IT/profil/:slug()/embed/collections-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/collections-step-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsement___fr-FR",
    path: "/profil/:slug()/embed/endorsement",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsement.vue")
  },
  {
    name: "profil-slug-embed-endorsement___en-GB",
    path: "/en-GB/profil/:slug()/embed/endorsement",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsement.vue")
  },
  {
    name: "profil-slug-embed-endorsement___es-ES",
    path: "/es-ES/profil/:slug()/embed/endorsement",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsement.vue")
  },
  {
    name: "profil-slug-embed-endorsement___it-IT",
    path: "/it-IT/profil/:slug()/embed/endorsement",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsement.vue")
  },
  {
    name: "profil-slug-embed-endorsements-grid___fr-FR",
    path: "/profil/:slug()/embed/endorsements-grid",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-grid.vue")
  },
  {
    name: "profil-slug-embed-endorsements-grid___en-GB",
    path: "/en-GB/profil/:slug()/embed/endorsements-grid",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-grid.vue")
  },
  {
    name: "profil-slug-embed-endorsements-grid___es-ES",
    path: "/es-ES/profil/:slug()/embed/endorsements-grid",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-grid.vue")
  },
  {
    name: "profil-slug-embed-endorsements-grid___it-IT",
    path: "/it-IT/profil/:slug()/embed/endorsements-grid",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-grid.vue")
  },
  {
    name: "profil-slug-embed-endorsements-infinite-slider___fr-FR",
    path: "/profil/:slug()/embed/endorsements-infinite-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-infinite-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-infinite-slider___en-GB",
    path: "/en-GB/profil/:slug()/embed/endorsements-infinite-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-infinite-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-infinite-slider___es-ES",
    path: "/es-ES/profil/:slug()/embed/endorsements-infinite-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-infinite-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-infinite-slider___it-IT",
    path: "/it-IT/profil/:slug()/embed/endorsements-infinite-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-infinite-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-page-slider___fr-FR",
    path: "/profil/:slug()/embed/endorsements-page-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-page-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-page-slider___en-GB",
    path: "/en-GB/profil/:slug()/embed/endorsements-page-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-page-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-page-slider___es-ES",
    path: "/es-ES/profil/:slug()/embed/endorsements-page-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-page-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-page-slider___it-IT",
    path: "/it-IT/profil/:slug()/embed/endorsements-page-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-page-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-step-slider___fr-FR",
    path: "/profil/:slug()/embed/endorsements-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-step-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-step-slider___en-GB",
    path: "/en-GB/profil/:slug()/embed/endorsements-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-step-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-step-slider___es-ES",
    path: "/es-ES/profil/:slug()/embed/endorsements-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-step-slider.vue")
  },
  {
    name: "profil-slug-embed-endorsements-step-slider___it-IT",
    path: "/it-IT/profil/:slug()/embed/endorsements-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/endorsements-step-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-grid___fr-FR",
    path: "/profil/:slug()/embed/qualities-grid",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-grid.vue")
  },
  {
    name: "profil-slug-embed-qualities-grid___en-GB",
    path: "/en-GB/profil/:slug()/embed/qualities-grid",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-grid.vue")
  },
  {
    name: "profil-slug-embed-qualities-grid___es-ES",
    path: "/es-ES/profil/:slug()/embed/qualities-grid",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-grid.vue")
  },
  {
    name: "profil-slug-embed-qualities-grid___it-IT",
    path: "/it-IT/profil/:slug()/embed/qualities-grid",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-grid.vue")
  },
  {
    name: "profil-slug-embed-qualities-infinite-slider___fr-FR",
    path: "/profil/:slug()/embed/qualities-infinite-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-infinite-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-infinite-slider___en-GB",
    path: "/en-GB/profil/:slug()/embed/qualities-infinite-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-infinite-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-infinite-slider___es-ES",
    path: "/es-ES/profil/:slug()/embed/qualities-infinite-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-infinite-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-infinite-slider___it-IT",
    path: "/it-IT/profil/:slug()/embed/qualities-infinite-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-infinite-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-page-slider___fr-FR",
    path: "/profil/:slug()/embed/qualities-page-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-page-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-page-slider___en-GB",
    path: "/en-GB/profil/:slug()/embed/qualities-page-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-page-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-page-slider___es-ES",
    path: "/es-ES/profil/:slug()/embed/qualities-page-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-page-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-page-slider___it-IT",
    path: "/it-IT/profil/:slug()/embed/qualities-page-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-page-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-step-slider___fr-FR",
    path: "/profil/:slug()/embed/qualities-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-step-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-step-slider___en-GB",
    path: "/en-GB/profil/:slug()/embed/qualities-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-step-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-step-slider___es-ES",
    path: "/es-ES/profil/:slug()/embed/qualities-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-step-slider.vue")
  },
  {
    name: "profil-slug-embed-qualities-step-slider___it-IT",
    path: "/it-IT/profil/:slug()/embed/qualities-step-slider",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/qualities-step-slider.vue")
  },
  {
    name: "profil-slug-embed-quality___fr-FR",
    path: "/profil/:slug()/embed/quality",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/quality.vue")
  },
  {
    name: "profil-slug-embed-quality___en-GB",
    path: "/en-GB/profil/:slug()/embed/quality",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/quality.vue")
  },
  {
    name: "profil-slug-embed-quality___es-ES",
    path: "/es-ES/profil/:slug()/embed/quality",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/quality.vue")
  },
  {
    name: "profil-slug-embed-quality___it-IT",
    path: "/it-IT/profil/:slug()/embed/quality",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/embed/quality.vue")
  },
  {
    name: "profil-slug___fr-FR",
    path: "/profil/:slug()",
    meta: index8CwUAg4kjJMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/index.vue")
  },
  {
    name: "profil-slug___en-GB",
    path: "/en-GB/profil/:slug()",
    meta: index8CwUAg4kjJMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/index.vue")
  },
  {
    name: "profil-slug___es-ES",
    path: "/es-ES/profil/:slug()",
    meta: index8CwUAg4kjJMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/index.vue")
  },
  {
    name: "profil-slug___it-IT",
    path: "/it-IT/profil/:slug()",
    meta: index8CwUAg4kjJMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/index.vue")
  },
  {
    name: "profil-slug-qualite-id___fr-FR",
    path: "/profil/:slug()/qualite/:id()",
    meta: _91id_93ZSVlfvB2qSMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/qualite/[id].vue")
  },
  {
    name: "profil-slug-qualite-id___en-GB",
    path: "/en-GB/profil/:slug()/qualite/:id()",
    meta: _91id_93ZSVlfvB2qSMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/qualite/[id].vue")
  },
  {
    name: "profil-slug-qualite-id___es-ES",
    path: "/es-ES/profil/:slug()/qualite/:id()",
    meta: _91id_93ZSVlfvB2qSMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/qualite/[id].vue")
  },
  {
    name: "profil-slug-qualite-id___it-IT",
    path: "/it-IT/profil/:slug()/qualite/:id()",
    meta: _91id_93ZSVlfvB2qSMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/qualite/[id].vue")
  },
  {
    name: "profil-slug-reference-id___fr-FR",
    path: "/profil/:slug()/reference/:id()",
    meta: _91id_93HPLJnA5qyZMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/reference/[id].vue")
  },
  {
    name: "profil-slug-reference-id___en-GB",
    path: "/en-GB/profil/:slug()/reference/:id()",
    meta: _91id_93HPLJnA5qyZMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/reference/[id].vue")
  },
  {
    name: "profil-slug-reference-id___es-ES",
    path: "/es-ES/profil/:slug()/reference/:id()",
    meta: _91id_93HPLJnA5qyZMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/reference/[id].vue")
  },
  {
    name: "profil-slug-reference-id___it-IT",
    path: "/it-IT/profil/:slug()/reference/:id()",
    meta: _91id_93HPLJnA5qyZMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/reference/[id].vue")
  },
  {
    name: "profil-slug-rejoindre___fr-FR",
    path: "/profil/:slug()/rejoindre",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/rejoindre.vue")
  },
  {
    name: "profil-slug-rejoindre___en-GB",
    path: "/en-GB/profil/:slug()/rejoindre",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/rejoindre.vue")
  },
  {
    name: "profil-slug-rejoindre___es-ES",
    path: "/es-ES/profil/:slug()/rejoindre",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/rejoindre.vue")
  },
  {
    name: "profil-slug-rejoindre___it-IT",
    path: "/it-IT/profil/:slug()/rejoindre",
    component: () => import("/vercel/path0/frontend/marketplace/pages/profil/[slug]/rejoindre.vue")
  },
  {
    name: "solutions-articles-slug___fr-FR",
    path: "/solutions/articles/:slug()",
    meta: _91slug_93CQ1MNEVGJaMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/[slug].vue")
  },
  {
    name: "solutions-articles-slug___en-GB",
    path: "/en-GB/solutions/articles/:slug()",
    meta: _91slug_93CQ1MNEVGJaMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/[slug].vue")
  },
  {
    name: "solutions-articles-slug___es-ES",
    path: "/es-ES/solutions/articles/:slug()",
    meta: _91slug_93CQ1MNEVGJaMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/[slug].vue")
  },
  {
    name: "solutions-articles-slug___it-IT",
    path: "/it-IT/solutions/articles/:slug()",
    meta: _91slug_93CQ1MNEVGJaMeta || {},
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/[slug].vue")
  },
  {
    name: "solutions-articles___fr-FR",
    path: "/solutions/articles",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/index.vue")
  },
  {
    name: "solutions-articles___en-GB",
    path: "/en-GB/solutions/articles",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/index.vue")
  },
  {
    name: "solutions-articles___es-ES",
    path: "/es-ES/solutions/articles",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/index.vue")
  },
  {
    name: "solutions-articles___it-IT",
    path: "/it-IT/solutions/articles",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/index.vue")
  },
  {
    name: "solutions-articles-tags-category-tag___fr-FR",
    path: "/solutions/articles/tags/:category()/:tag()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/[tag].vue")
  },
  {
    name: "solutions-articles-tags-category-tag___en-GB",
    path: "/en-GB/solutions/articles/tags/:category()/:tag()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/[tag].vue")
  },
  {
    name: "solutions-articles-tags-category-tag___es-ES",
    path: "/es-ES/solutions/articles/tags/:category()/:tag()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/[tag].vue")
  },
  {
    name: "solutions-articles-tags-category-tag___it-IT",
    path: "/it-IT/solutions/articles/tags/:category()/:tag()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/[tag].vue")
  },
  {
    name: "solutions-articles-tags-category___fr-FR",
    path: "/solutions/articles/tags/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/index.vue")
  },
  {
    name: "solutions-articles-tags-category___en-GB",
    path: "/en-GB/solutions/articles/tags/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/index.vue")
  },
  {
    name: "solutions-articles-tags-category___es-ES",
    path: "/es-ES/solutions/articles/tags/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/index.vue")
  },
  {
    name: "solutions-articles-tags-category___it-IT",
    path: "/it-IT/solutions/articles/tags/:category()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/articles/tags/[category]/index.vue")
  },
  {
    name: "solutions-collections-slug___fr-FR",
    path: "/solutions/collections/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/collections/[slug].vue")
  },
  {
    name: "solutions-collections-slug___en-GB",
    path: "/en-GB/solutions/collections/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/collections/[slug].vue")
  },
  {
    name: "solutions-collections-slug___es-ES",
    path: "/es-ES/solutions/collections/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/collections/[slug].vue")
  },
  {
    name: "solutions-collections-slug___it-IT",
    path: "/it-IT/solutions/collections/:slug()",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/collections/[slug].vue")
  },
  {
    name: "solutions___fr-FR",
    path: "/solutions",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/index.vue")
  },
  {
    name: "solutions___en-GB",
    path: "/en-GB/solutions",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/index.vue")
  },
  {
    name: "solutions___es-ES",
    path: "/es-ES/solutions",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/index.vue")
  },
  {
    name: "solutions___it-IT",
    path: "/it-IT/solutions",
    component: () => import("/vercel/path0/frontend/marketplace/pages/solutions/index.vue")
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/api/referral",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/auth/deconnexion",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tarifs",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/profil/scenes-de-vie-D4pSKWvTJf2/qualite/ZIpq515w6QK",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/profil/adventure-conseil-tUEXuLZ5bL",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/profil/samm-trading-kroramW$mC",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit/maintenance-et-infogerance",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit/design",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit/conception-app-et-service-digital",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit/prototypage-et-bureau-detude",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit/industrialisation-et-production",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit/support-client-et-centre-dappel",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit/developpement",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit/conception-produit",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/tech-et-produit/business-intelligence-et-data",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances/avocats-et-accompagnement-juridique",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances/subventions-et-fonds-publics",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances/investissement-et-levee-de-fonds",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances/assurance-et-courtage",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances/conseil-financier",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances/comptabilite-et-commissariat-aux-comptes",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances/services-administratifs-et-formalites",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances/croissance-externe-fusions-et-acquisitions",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/legal-compta-et-finances/restructuring-entreprises-en-difficulte",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/demenagement-et-relocation",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/traduction-et-localisation",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/acceleration-et-incubation",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/imprimerie",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/logistique",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/autres",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/traiteur-et-restauration",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/sourcing-et-imports",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/office-management",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/expansion-internationale",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/bureaux-et-immobilier-dentreprise",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/operations-et-divers/architecture-et-design-dinterieur",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh/marque-employeur",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh/gestion-paye-et-social",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh/bien-etre-et-happiness",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh/mobilite-et-portage",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh/temporaire-interim-freelances",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh/teambuilding-et-seminaires",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh/formation-coaching-et-developpement-personnel",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh/conseil-en-management-et-organisation",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/recrutement-et-rh/chasse-de-tete-et-recrutement",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes/evenementiel-et-salons",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes/marketing-digital-et-growth",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes/content-et-production-editoriale",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes/relations-presse-et-publiques",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes/production-video-et-photographie",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes/goodies-et-cadeaux-daffaires",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes/branding-communication-et-publicite",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes/representation-commerciale-et-prospection",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/marketing-communication-et-ventes/strategie-marketing-et-positionnement",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/les-all-stars-trustfolio-de-lannee-2020",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-2022",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-automne-2021",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-ete-2021",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-ete-2022",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-hiver-2021",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-hiver-2022",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-printemps-2021",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-printemps-2022",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-trustfolio-quatrieme-trimestre-2019-copy",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-trustfolio-second-trimestre-2019",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-trustfolio-cond-trimestre-2020",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-trustfolio-troisieme-trimestre-2019",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/all-stars-trustfolio-troisieme-trimestre-2020",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/articles/les-prestashare-all-stars-du-permier-trimestre-2019",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/fr-FR-sitemap.xml",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/en-GB-sitemap.xml",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/es-ES-sitemap.xml",
    component: component_45stubHUXpOjlvHk
  },
  {
    name: component_45stubHUXpOjlvHkMeta?.name,
    path: "/it-IT-sitemap.xml",
    component: component_45stubHUXpOjlvHk
  }
]