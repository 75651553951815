
// @ts-nocheck


export const localeCodes =  [
  "fr-FR",
  "en-GB",
  "es-ES",
  "it-IT"
]

export const localeLoaders = {
  "fr-FR": [{ key: "../node_modules/@trustfolio/i18n/dist/marketplace/fr.json", load: () => import("../node_modules/@trustfolio/i18n/dist/marketplace/fr.json" /* webpackChunkName: "locale__vercel_path0_frontend_marketplace_node_modules__64trustfolio_i18n_dist_marketplace_fr_json" */), cache: true }],
  "en-GB": [{ key: "../node_modules/@trustfolio/i18n/dist/marketplace/en.json", load: () => import("../node_modules/@trustfolio/i18n/dist/marketplace/en.json" /* webpackChunkName: "locale__vercel_path0_frontend_marketplace_node_modules__64trustfolio_i18n_dist_marketplace_en_json" */), cache: true }],
  "es-ES": [{ key: "../node_modules/@trustfolio/i18n/dist/marketplace/es.json", load: () => import("../node_modules/@trustfolio/i18n/dist/marketplace/es.json" /* webpackChunkName: "locale__vercel_path0_frontend_marketplace_node_modules__64trustfolio_i18n_dist_marketplace_es_json" */), cache: true }],
  "it-IT": [{ key: "../node_modules/@trustfolio/i18n/dist/marketplace/it.json", load: () => import("../node_modules/@trustfolio/i18n/dist/marketplace/it.json" /* webpackChunkName: "locale__vercel_path0_frontend_marketplace_node_modules__64trustfolio_i18n_dist_marketplace_it_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "fr-FR",
      "language": "fr-FR",
      "files": [
        "/vercel/path0/frontend/marketplace/node_modules/@trustfolio/i18n/dist/marketplace/fr.json"
      ]
    },
    {
      "code": "en-GB",
      "language": "en-GB",
      "files": [
        "/vercel/path0/frontend/marketplace/node_modules/@trustfolio/i18n/dist/marketplace/en.json"
      ]
    },
    {
      "code": "es-ES",
      "language": "es-ES",
      "files": [
        "/vercel/path0/frontend/marketplace/node_modules/@trustfolio/i18n/dist/marketplace/es.json"
      ]
    },
    {
      "code": "it-IT",
      "language": "it-IT",
      "files": [
        "/vercel/path0/frontend/marketplace/node_modules/@trustfolio/i18n/dist/marketplace/it.json"
      ]
    }
  ],
  "defaultLocale": "fr-FR",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "./node_modules/@trustfolio/i18n/dist/marketplace",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "seo": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "fr-FR",
    "language": "fr-FR",
    "files": [
      {
        "path": "/vercel/path0/frontend/marketplace/node_modules/@trustfolio/i18n/dist/marketplace/fr.json"
      }
    ]
  },
  {
    "code": "en-GB",
    "language": "en-GB",
    "files": [
      {
        "path": "/vercel/path0/frontend/marketplace/node_modules/@trustfolio/i18n/dist/marketplace/en.json"
      }
    ]
  },
  {
    "code": "es-ES",
    "language": "es-ES",
    "files": [
      {
        "path": "/vercel/path0/frontend/marketplace/node_modules/@trustfolio/i18n/dist/marketplace/es.json"
      }
    ]
  },
  {
    "code": "it-IT",
    "language": "it-IT",
    "files": [
      {
        "path": "/vercel/path0/frontend/marketplace/node_modules/@trustfolio/i18n/dist/marketplace/it.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
