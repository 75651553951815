import revive_payload_client_9yDAZBCpuL from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XGmck1195S from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XhlqUk3sWs from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_2Oicnw9dGa from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.17_magicast@0.3.5_rollup@3.29.4_vite@5.4.6_@types+node@22.5.5_sass@1.78._n6ql4lmi2ovib3glgdtrpt7ary/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_g34FAC9Zk9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3Gy4mUBko9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_33urX1IqfG from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_DrVWFJ4wIx from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/frontend/marketplace/.nuxt/components.plugin.mjs";
import prefetch_client_AKq4D5hZGo from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@0.1.13_es_jys5w7c5bgkdibhzrwici2rtyy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fJqmXJCAd2 from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@3.29.4_vite@5.4.6_@types+node@22.5.5_sass@1.78.0_terse_sqnxtjoprxfl7ueifczzsvjday/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_3q0EK7N2ka from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@3.29.4_vue@3.5.6_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_VYiMMvS292 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@3.29.4_vue@3.5.6_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_HjD4dN7rpE from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@types+node@22.5.5_@upstash+redis@1.34.0_encoding@_fd5opi4iuuhaa73h22mu2w2khe/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_gCBhaO3vJW from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@3.29.4_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import strapi_cXsJEjdbsU from "/vercel/path0/node_modules/.pnpm/@nuxtjs+strapi@1.12.0_magicast@0.3.5_rollup@3.29.4_webpack-sources@3.2.3/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import sentry_client_FyK0dwF6Ij from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._svudmardc7qnkrah4n6lktqvsq/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/frontend/marketplace/.nuxt/sentry-client-config.mjs";
import youtube_client_RwCHrRW0F5 from "/vercel/path0/frontend/marketplace/plugins/youtube.client.js";
import analytics_client_mtqVw3YRXk from "/vercel/path0/frontend/marketplace/plugins/analytics.client.ts";
import autoResizeText_client_4EwxxWKW3M from "/vercel/path0/frontend/marketplace/plugins/autoResizeText.client.js";
import recaptcha_85gNSCNFUU from "/vercel/path0/frontend/marketplace/plugins/recaptcha.ts";
export default [
  revive_payload_client_9yDAZBCpuL,
  unhead_XGmck1195S,
  router_XhlqUk3sWs,
  _0_siteConfig_2Oicnw9dGa,
  payload_client_g34FAC9Zk9,
  navigation_repaint_client_3Gy4mUBko9,
  check_outdated_build_client_33urX1IqfG,
  chunk_reload_client_DrVWFJ4wIx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_AKq4D5hZGo,
  plugin_fJqmXJCAd2,
  switch_locale_path_ssr_3q0EK7N2ka,
  i18n_VYiMMvS292,
  plugin_HjD4dN7rpE,
  plugin_client_gCBhaO3vJW,
  strapi_cXsJEjdbsU,
  sentry_client_FyK0dwF6Ij,
  sentry_client_config_o34nk2sJbg,
  youtube_client_RwCHrRW0F5,
  analytics_client_mtqVw3YRXk,
  autoResizeText_client_4EwxxWKW3M,
  recaptcha_85gNSCNFUU
]